<template>
  <div>
    <siteHeader />
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <form>
              <h3>30,60,90 Report</h3>
              <hr class="my-4" />
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-12">
                    <base-input label="List Member">
                      <el-select
                        style="width: 100%"
                        v-model="model.membership"
                        placeholder="List Member"
                      >
                        <el-option
                          v-for="option in membershipData"
                          :value="option.id"
                          :label="option.name + ' ' + option.lastname"
                          :key="option.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-4">
                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="onSearch()"
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="reset()"
                  >
                    Reset
                  </button>
                  <button
                    type="button"
                    class="btn btn-default"
                    v-on:click="exportreport()"
                  >
                    Export
                  </button>
                </div>
              </div>
            </form>
            <br />
            <div class="table-responsive">
              <base-table
                class="table align-items-center table-flush"
                :class="type === 'dark' ? 'table-dark' : ''"
                :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
                tbody-classes="list"
                :data="recieptData"
              >
                <template v-slot:columns>
                  <th>Member</th>
                  <th>Address</th>
                  <th>30 Days Amount($)</th>
                  <th>60 Days Amount($)</th>
                  <th>90 Days Amount($)</th>
                </template>

                <template v-slot:default="row">
                  <td class="budget">
                    {{ row.item.name }}
                  </td>
                  <td class="budget">${{ row.item.address }}</td>
                  <td class="budget">${{ row.item.d30 }}</td>
                  <td class="budget">${{ row.item.d60 }}</td>
                  <td class="budget">${{ row.item.d90 }}</td>
                </template>
              </base-table>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const chargesRepository = RepositoryFactory.get("chargesRepo");
const addchargeRepository = RepositoryFactory.get("addcharge");
const memberRepository = RepositoryFactory.get("member");

import { ElSelect, ElOption } from "element-plus";
export default {
  components: {
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  name: "charge_report",

  data() {
    return {
      isLoading: false,
      isUpdateLoading: false,
      membershipData: [],
      chargeData: [],
      itemData: [],
      subscriptionData: [],
      recieptData: [],
      model: {},
      total: 0,
    };
  },

  methods: {
    exportreport() {
      window.open(
        "https://shulsoftware.adass.com.au/api/index.php/AccountAddCharge/monthexport",
        "_blank"
      );
    },
    reset() {
      this.recieptData = [];
      this.total = 0;
      this.model = {};

      this.getReport();
    },
    async onSearch() {
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      this.tableData = await addchargeRepository.getReport306090(data);

      this.recieptData = this.tableData.data;
    },

    getChargeList() {
      chargesRepository.getCharges().then((data) => {
        this.tableData = Object.freeze(data);
        this.chargeData = this.tableData.data;
      });
    },

    async getList() {
      this.tableData = await memberRepository.getMember();
      this.membershipData = this.tableData.data;
    },

    async getReport() {
      this.tableData = await addchargeRepository.getReport306090();
      this.recieptData = this.tableData.data;
    },
  },
  mounted() {
    this.getReport();
    this.getList();
  },
};
</script>
<style></style>
